<template>
  <b-card title="إضافة معلم جديد" class="col-12 col-lg-6 c">
    <div>
      <div ref="alert"></div>
      <div class="form-group">
        <label for=""><h5>الإسم</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="name"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>رقم الهوية</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="number"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>الجوال</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="phone"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>اسم المستخدم (لتسجيل الدخول)</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="username"
        />
      </div>
      <div class="form-group">
        <label for=""><h5>كلمة المرور (لتسجيل الدخول)</h5></label>
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="أكتب هنا.."
          ref="password"
        />
      </div>
      <div class="row">
        <div class="col-12 g">
            <h5>
                الصلاحيات
            </h5>
        </div>
        <div class="col-12" v-for="c in classes" :key="c.title">
            <div class="card card-body border g">
                <div class="form-check" v-for="cl in c.classrooms" :key="c+cl">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="pers" :value="'class,' + c.title + ',' + cl">
                    {{ c.title }} - {{ cl }}
                  </label>
                </div>
            </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <button
          class="btn btn-success"
          style="margin: 0px auto"
          @click="addTeacher()"
        >
          إضافة المعلم الآن
        </button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
  },
  data(){
    return {
        user: JSON.parse(localStorage.getItem('user')),
        pers: [],
        classes: []
    }
  },
  created(){
    var g = this;
    setTimeout(function(){
        g.$refs.username.value = Math.random().toString().split(".")[1].substring(0,6)
        g.$refs.password.value = Math.random().toString().split(".")[1].substring(0,6)
    }, 500)
    $.post(api + '/user/students/classes', {
        jwt: this.user.jwt
    }).then(function(r){
        g.loading = false
        if(r.status != 100){
            alert(r.response)
        }else{
            g.classes = r.response
        }
    }).fail(function(){
    })
  },
  methods: {
    addTeacher() {
      var name = this.$refs.name.value,
        number = this.$refs.number.value,
        phone = this.$refs.phone.value,
        username = this.$refs.username.value,
        password = this.$refs.password.value,
        _g = this, g = this;
      if (
        name == "" ||
        name == undefined ||
        number == "" ||
        number == undefined ||
        phone == "" ||
        phone == undefined ||
        username == "" ||
        username == undefined ||
        password == "" ||
        password == undefined
      ) {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "هناك حقول مطلوبة",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      } else {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "جاري التحميل..",
            icon: "WarningIcon",
            variant: "warning",
          },
        });
        axios
          .post(localStorage.getItem("api") + "/user/lessons/teachers/add", {
            name: this.$refs.name.value,
            phone: this.$refs.phone.value,
            number: this.$refs.number.value,
            username: this.$refs.username.value,
            password: this.$refs.password.value,
            pers: this.pers,
            jwt: g.user.jwt,
          })
          .then(function (r) {
            _g.$toast.clear();
            if (r.data.status == 100) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم اضافة المعلم بنجاح",
                  icon: "WarningIcon",
                  variant: "success",
                },
              });
              g.$refs.username.value = Math.random().toString().split(".")[1].substring(0,6)
              g.$refs.password.value = Math.random().toString().split(".")[1].substring(0,6)
              g.$refs.name.value = ""
              g.$refs.phone.value = ""
              g.$refs.number.value = ""
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: r.data.response,
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function (e) {
            console.log(1,e)
            _g.$toast.clear();
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
};
</script>

<style>
</style>
